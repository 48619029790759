@import '../../../../styles/const.scss';
@import '../../../../styles/mixins.scss';
.am {
  .screen1-container {
    background-image: url('../../../../assets/compressed/landing-page-background.jpg');
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    .screen1-backdrop{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      position: relative;
      justify-content: center;
      top: 0;
      background-image: radial-gradient(circle at 0% 52.3743%, transparent 0px, rgba(0, 0, 0, 0.7) 1px);
      // background-color: rgba(0, 0, 0, 0.5);
      -webkit-tap-highlight-color: transparent;
      height: 100%;
      color: white;

      .screen1-content {
        padding: 20% 30% 100px 50px;

        .screen1-header {
          font-size: 4rem;
          color: $custom-green;
          font-family: ProximaNova-Bold;
        }
  
        .screen1-subheader {
          font-size: 3em;
          font-family: ProximaNova-Bold;
          line-height: 1.2;
        }

        .subtext {
          font-family: ProximaNova-Regular;
          font-size: 1.5em;
        }
  
      }

      .actions-container {
        // padding: 2% 2% 6%;
        text-align: center;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform:translateX(-50%);
        $color: #4aa79a;

        .action-button {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          position:relative;
          animation: ripple 0.7s linear infinite;
  
          @keyframes ripple {
            0% {
              box-shadow: 0 0 0 0 rgba($color, 0.3),
                          0 0 0 .5em rgba($color, 0.3),
                          0 0 0 .8em rgba($color, 0.3),
                          0 0 0 1em rgba($color, 0.3);
            }
            100% {
              box-shadow: 0 0 0 .5em rgba($color, 0.3),
                          0 0 0 .8em rgba($color, 0.3),
                          0 0 0 1em rgba($color, 0.3),
                          0 0 0 1.5em rgba($color, 0);
            }
          }
    
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  @include mobile {
    .screen1-container {
      .screen1-backdrop{
        .screen1-content {
          padding: 0px 50px 0 20px;
          overflow-y: auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .screen1-header {
            font-size: 2.5rem;
          }

          .screen1-subheader {
            font-size: 1.5rem;
          }

          .subtext {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  
}
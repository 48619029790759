// const file is store the css constants so that we can make the change at one place and it will reflect everywhere


$gradient-id: '#amGradient';
$menu-width: 25%;

// Color codes
$white: #fff;
$black: #000;
$light-gray: #f0f0f0;
$gray: #bfbfbf;
$bright-green: #59b273;
$custom-green: #00a69a;

// Social Media
$facebook: #4e598c;
$linkedIn: #4376ae;
$twitter: #6595d6;


$gradient: linear-gradient(to right, #00f08a 5%, #009bff 95%);

$box-shadow: 0 16px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';

// services screen specific CSS will come here

.am {
  .services-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 2rem;
    padding-right: 0;

    .first-section {
      width: 40%;
      margin: auto 0;
      padding: 2rem 0 ;
      .services-content {
        width: 90%;
        margin: auto;
        padding: 20px 0;

        .image-card{
          height: 300px;
          box-shadow: $box-shadow;
          border-radius: 10px;
  
          .service-img {
            height: 100%;
          }  
        }
        @include mobile{
          .image-card{
            height: 240px;
          }
        }
        .heading {
          font-size: 3em;
          font-family: ProximaNova-Bold;
          margin-bottom: .5rem;
        }

        .text {
          font-size: 1.25rem;
        }
      }
    }
    
    .second-section {
      width: 60%;
      margin: auto 0;
      padding: 2rem 40px;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .carousel-item-container {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        padding-left: 500px;

        .carousel-item {
          margin: 0 4%;
          transition: all .5s ease-in-out;

          &.new {
            pointer-events: none;
            .am-card {
              .am-card-content {
                .list {
                  display: none;
                }
              }
            }
          }
  
          &.right {
            &.new {
              transform: scale(0.8);
              opacity: 0.8;
            }
    
            &.active {
              transform: scale(1);
              opacity: 1;
              z-index: 2;
              // animation: slide-in .5s ease-in-out;
              margin-left: calc(-325px - 4%);
              &:first-of-type{
                margin-left: -500px;
              }
              @keyframes slide-in {
                0% {
                  margin-left: 0;
                }
    
                100% {
                  margin-left: -300px;
                }
                
              }
            }
    
            &.hidden {
              display: none;
            }
  
            &.old {
              margin-left: calc(-325px - 4%);
              // animation: slide-out .5s ease-in-out;
              z-index: 1;  
              // margin-bottom: -30px;
              transform: translateY(20px);
              opacity: .3;
              transition-delay: .3s;
              &:first-of-type{
                margin-left: -500px;
              }
              @keyframes slide-out {
                0% {
                  z-index: 2;
                }
    
                100% {
                  z-index: 1;
                }
                
              }
            }
          }

          &.left {
            &.new {
              transform: scale(0.8);
              opacity: 0.8;
              z-index: 2;
              // animation: left-slide-out .5s ease-in-out;
              
              @keyframes left-slide-out {
                0% {
                  opacity: 1;
                  transform: scale(1);
                  margin-left: -500px;
                }
    
                100% {
                  opacity: 0.8;
                  transform: scale(0.8);
                  margin-left: 0;
                }
                
              }
            }
    
            &.active {
              transform: scale(1);
              opacity: 1;
              z-index: 2;
              margin-left: -500px;
              -webkit-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
              -moz-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
              -o-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
              transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
              &:not(:first-of-type){

                margin-left: calc(-325px - 4%);
              }
              // animation: left-focus .5s ease-in-out;

              @keyframes left-focus {
                0% {
                  opacity: 0;
                }
    
                100% {
                  opacity: 1;
                }
                
              }
            }
    
            &.hidden {
              display: none;
            }
  
            &.old {
              margin-left: calc(-325px - 4%);
              opacity: 0.3;
              transform: translateY(20px);
              // animation: left-focus .5s ease-in-out;
              &:first-of-type{
                margin-left: -500px;
              }
              @keyframes left-focus {
                0% {
                  opacity: 0;
                }
    
                100% {
                  opacity: 1;
                }
                
              }
            }
          }  

        }

        // .carousel-item:nth-child(n + 4) {
        //   --y: calc(-50% + -30px);
        //   transform: translateX(var(--y)) scale(0.75);
        //   box-shadow: 0 0 1px 1px #00000003;
        // }
        // .carousel-item:nth-child(3) {
        //   --y: calc(-50% + -15px);
        //   transform: translateX(var(--y))  scale(0.75);
        // }
        // .carousel-item:nth-child(2) {
        //   --y: calc(-50%);
        //   transform: translateX(var(--y))  scale(0.75);
        // }
        // .carousel-item:nth-child(1) {
        //   --y: calc(-50% + 15px);
        //   transform: translateX(var(--y)) scale(1);
        // }
        
      }

      .arrow-buttons-container {
        display: flex;
        margin: calc(5% + 20px) 0 0 10%;

        .prev-btn-container {
          margin-right: 5px;
        }

        .arrow-buttons {
          color: white;
          background: $gradient;
          border-radius: 25%;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          &:disabled {
            color: $gray;
            background: $light-gray;
            // border: solid 1px $gray;
            box-shadow: none;
            cursor:not-allowed;
          }
        }
      }
    }
  }

  @include tablet {
    .services-container {
      display: block;
      min-height: calc(100 * var(--vh) );
      height: calc(100 * var(--vh)) !important;
      flex-direction: column;
      height: auto;
      // padding: 0 1rem;
      overflow: auto;
      .second-section {
        .carousel-item-container {
          padding: 5%;
          padding-left: 0px;
          .carousel-item {
            margin: 0 4%;
            transition: all .5s ease-in-out;
  
            &.new {
              pointer-events: none;
              .am-card {
                .am-card-content {
                  .list {
                    display: none;
                  }
                }
              }
            }
    
            &.right {
              &.new {
                transform: scale(0.8);
                opacity: 0.8;
              }
      
              &.active {
                transform: scale(1);
                opacity: 1;
                z-index: 2;
                // animation: slide-in .5s ease-in-out;
                margin-left: calc(-325px - 4%);
                &:first-of-type{
                  margin-left: 0;
                }
                @keyframes slide-in {
                  0% {
                    margin-left: 0;
                  }
      
                  100% {
                    margin-left: -300px;
                  }
                  
                }
              }
      
              &.hidden {
                display: none;
              }
    
              &.old {
                margin-left: calc(-325px - 4%);
                // animation: slide-out .5s ease-in-out;
                z-index: 1;  
                // margin-bottom: -30px;
                transform: translateY(20px);
                opacity: .3;
                transition-delay: .3s;
                &:first-of-type{
                  margin-left: 0;
                }
                @keyframes slide-out {
                  0% {
                    z-index: 2;
                  }
      
                  100% {
                    z-index: 1;
                  }
                  
                }
              }
            }
  
            &.left {
              &.new {
                transform: scale(0.8);
                opacity: 0.8;
                z-index: 2;
                
              }
      
              &.active {
                transform: scale(1);
                opacity: 1;
                z-index: 2;
                margin-left:0 ;
                -webkit-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
                -moz-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
                -o-transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
                transition: transform 0.3s ease-out 0.3s, 0s opacity 0s ease;
                &:not(:first-of-type){
  
                  margin-left: calc(-325px - 4%);
                }
              }
      
              &.hidden {
                display: none;
              }
    
              &.old {
                margin-left: calc(-325px - 4%);
                opacity: 0.3;
                transform: translateY(20px);
                &:first-of-type{
                  margin-left: -0px;
                }
              }
            }  
  
          }
        }     
      }

      .first-section, .second-section {
        width: 100%;
      }
    }
    
  }
  
  @include mobile {
    .services-container {
      flex-direction: column;
      padding: 0;
      display: block;
      height: calc(100 * var(--vh) - 80px);

      .first-section {
        width: 100%;
      }

      .second-section {
        width: 100%;
        max-width: 400px;
        margin: auto;
        overflow-x: inherit;

        .carousel-item-container {
          padding: 5%;
        }     
      }
    }
  }
  @include mobile-small {
    .services-container{
      .second-section {
        padding: 0 20px;
      }
    }
  }
  
}


@import '../styles/const.scss';
@import '../styles/mixins.scss';
@include mobile-small{
    html{
    font-size: 15px;
  }
}
.MuiDialog-paperFullScreen{
  background-color: transparent !important;
}
.MuiDialog-root{
  z-index: 1 !important;
}
.am {
  .am-active{
    color: $custom-green;
  }

  .am-inactive{
    &:hover {
      color: $custom-green;
    }
  }

  .am-details-dialog {
    padding: 0;
    scroll-behavior: smooth;
    &:first-child {
      padding-top: 0;
    }
  }
}
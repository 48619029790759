
@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';
.am {
  
  .project-details-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    .back-button-container {
      padding: 10px 0;
      align-self: flex-end;
      width: 75%;
      display: flex;
      justify-content: flex-end;
      position: fixed;
      /* background: #fff; */
      // top: 153px;
      left: 25%;
      padding: 10px 4%;
      transition: .5s;
      transform: translate(0,0);
      &.hide{
        transform: translate(0,-111%);
      }
      &.show{
        transform: translate(0,0);
      }
      .MuiButton-label {
        font-family: ProximaNova-Sbold;
      }
      
      .back-button {
        border-radius: 20px;
        box-shadow: $box-shadow;
        padding: 5px 20px;
        background-color: #fff;
        font-weight: bold;
      }
    }
    .details-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      background-color: #fff;
      // .back-button-container {
      //   padding: 10px 0;
      //   align-self: flex-start;
      //   .MuiButton-label {
      //     font-family: ProximaNova-Sbold;
      //   }

      //   .back-button {
      //     border-radius: 20px;
      //     box-shadow: $box-shadow;
      //     padding: 5px 20px;
      //     font-weight: bold;
      //   }
      // }

      .image-container {
        padding: 10px 0;
        align-self: center;
        margin-top: 55px;

        .details-image {
          width: 75%;
          display: block;
          margin: 0 auto 30px;
          border-radius: 10px;
          box-shadow: $box-shadow;
        }
      }

      .subItem-container {
        padding: 1rem 0 0;
        align-self: flex-start;
        width: 100%;
      }
    }
  }


  @include mobile {
    .project-details-container {
      .details-content {
        padding: 0 20px 60px 20px;

        .image-container {
          .details-image {
            width: 90%;
          }
        }

        .subItem-container .des-title {
          font-size: 2.5em;
        }
        .back-button-container {
          width: 100%;
          left: 0;
          .back-button{
            width: 80px;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
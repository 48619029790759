.staffAug {
    padding: 20px;
    overflow: auto;
    height: 87vh;
    margin: auto;

    .dataBox {
        max-width: 992px;
        margin: auto;

        .title {
            text-align: center;
            margin-bottom: 20px;

            h1 {
                color: #00a69a;
            }

            h2 {
                margin: 0;
            }
        }

        .collab {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            margin: 50px 0;
            gap: 20px;

            img {
                width: 150px;

                &.hand {
                    width: 50px;
                }
            }
        }

        .About {

            display: grid;
            grid-template-columns: 50% 50%;

            .contentBox {
                padding: 15px;
            }

            .img {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 50%;
                    border-radius: 12px;
                }
            }

        }

        .AboutFtechiz {
            .contentBox {
                padding: 15px;

                .btn-main {
                    background-color: #00a69a;
                    border-radius: 8px;
                    color: #FFFFFF;
                    border: none;
                    padding: 15px;
                    cursor: pointer;

                }
            }
        }

        .augCards {
            padding: 15px;

            h3 {
                text-align: center;
            }

            .contentBox {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                &.box2 {
                    grid-template-columns: repeat(2, 1fr);

                    .card {
                        border: none;
                        padding: 10px;
                        border-radius: 12px;
                        background: rgba($color: #e6e6e6, $alpha: 0.1);
                        margin: 0;
                        background: #FFFFFF;
                        box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.08);

                    }
                }

                .card {
                    // border: 1px solid #00a69a;
                    padding: 10px;
                    border-radius: 12px;
                    background: rgba($color: #00a69a, $alpha: 0.1);
                    margin: 0;
                }
            }
        }

    }

}

@media only screen and (max-width: 992px) {
    .staffAug {
        .dataBox {
            max-width: 992px;
            margin: auto;

            .About {
                .img {
                    img {
                        width: 100%;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:750px) {
    .staffAug {
        .dataBox {
            .augCards {
                .contentBox {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

        }

    }

}

@media only screen and (max-width:500px) {
    .staffAug {
        padding: 0;

        .dataBox {
            max-width: 992px;
            margin: auto;

            .collab {
                flex-direction: column;
            }

            .About {
                grid-template-columns: 100%;

                .img {
                    padding: 15px;
                }
            }

            .augCards {
                padding: 15px;

                h3 {
                    text-align: center;
                }

                .contentBox {
                    grid-template-columns: repeat(1, 1fr);

                    &.box2 {
                        grid-template-columns: repeat(1, 1fr);
                    }

                }
            }

        }

    }

}
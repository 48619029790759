@import '../../../../styles/const.scss';

.am {
  .subItem-container {

    .automated-power-control-text {
      font-size: 1.25rem;
      padding-bottom: 30%;

      .list-icon-container {
        .list-icon {
          width: 1.5rem;
          margin-right: 10px;
        }
      }

      .highlighted-text {
        font-size: 1.75rem;
        font-weight: bold;
        color: $custom-green;
        font-family: ProximaNova-Sbold;
      }

      .subtitle {
        font-weight: bold;
        font-size: 1.5rem;
      }

      .view-design {
        text-decoration: none;
        color: $custom-green;
      }

      .projectItem {
        margin: 35px 0;
      }

      .gallery {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        flex-wrap: nowrap;
        overflow: auto;
        margin-top: 15px;
        padding: 10px 0 25px 0;
      }

      .gallery img {
        width: 200px;
        height: auto;
        object-fit: cover;
        border: 4px solid #000000;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      }
      .btn-main {
        background-color: #00a69a;
        border-radius: 8px;
        color: #FFFFFF;
        border: none;
        padding: 15px;
        cursor: pointer;

      }
    }
  }
}
@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';

// services screen specific CSS will come here

.am {
  .inquiries-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
   
    .inquiries-paper {
      margin: auto;
      width: 70%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 10px;

      .contact-us-section {
        width: 70%;
      }

      .right-img {
        width: 30%;
        background: url('../../../assets/contact-image.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .form-container {
      padding: 20px 50px;

      .inquiries-title {
        font-family: ProximaNova-Bold;
      }
      
      .inquiries-form {
        margin: 20px 0;

        .form-field {
          margin: 5px 0;

          .form-label {
            color: $black;
            font-size: 1rem;
          }

          .form-input {
            margin-top: 1.5rem;
            
            &::before, &::after {
              border: none;
              background: $gradient;
              height: 2px;
            }
          }
        }

        .action-buttons {
          text-align: center;

          .submit-button {
            background: $gradient;
            color: $white;
            border-radius: 20px;
          }
        }
      }
    }

  }
  @include tablet{
   .inquiries-container .inquiries-paper{
      width: 80%;
    }
  }
  @include mobile{
    .inquiries-container{
      justify-content: center;
    }
  }
}

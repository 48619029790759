@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';

.am {
  .am-card {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 325px;
    // width: 50%;
    height: 600px;
    // margin: auto;
    color: $white;
    border-radius: 20px;

    .am-card-content {
      height: 100%;
      overflow-y: hidden;
      // background-color: rgba(0, 0, 0, 0.7);
      background-image: linear-gradient(0deg, rgba(0, 0, 0, .5), black);
      -webkit-tap-highlight-color: transparent;

      .am-card-header {
        font-size: xx-large;
        line-height: 1.2;
        cursor: pointer;
        color: $bright-green;
        transition: all .3s ease;
        width: 90%;
        /* added black background behind header text */
        background-color: black;
        padding: 5px;
        background-color: black;

        &:hover {
          // transform: scale(1.1); /* (110% zoom)*/
          transform: translateX(10px);
          color: $custom-green;
        }
      }

      ul.list {
        font-size: large;
        list-style: none;
        /* Remove default bullets */
        padding: 0;

        .list-item {
          cursor: pointer;
          margin: 0px 0 20px;
          transition: all .3s ease;
          display: flex;
          align-items: baseline;
          font-size: 1.2em;
          width: 90%;

          &::before {
            content: '';
            background-image: url('../../../assets/bullet.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            // content: url('../../../assets/bullet.svg');  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            // color: red; /* Change the color */
            // font-weight: bold; /* If you want it to be bold */
            // display: inline-block; /* Needed to add space between the bullet and the text */
            // width: 1.5em; /* Also needed for space (tweak if needed) */
            // margin-left: -1em; /* Also needed for space (tweak if needed) */
            // transform: scale(2);
            // vertical-align: super;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            font-size: 40px;
            line-height: 0;
            position: relative;
            top: 3px;
            flex: none;
            color: #fff;
          }

          &:hover {
            // transform: scale(1.1); /* (110% zoom)*/
            // margin-left: 10px;
            transform: translateX(10px);

            color: $custom-green;

            &::before {
              background-image: url('../../../assets/bullet-color.svg');
            }
          }
        }

        @include mobile-small {
          .list-item {
            width: calc(100%);

            &:hover {
              transform: translateX(5px);
            }
          }
        }
      }
    }

  }

  @include laptop {
    .am-card {
      width: 325px;
    }

  }

  @include tablet {
    .am-card {
      width: 325px;
    }
  }

  @include mobile {
    .am-card {
      width: 100%;
    }
  }
}

@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';

.am {
  .service-details-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    
    .details-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      background-color: #fff;
      padding: 0 40px 100px 40px;
      .no-top-margin {
        margin-top: 0;
      }

      .back-button-container {
        padding: 10px 0;
        align-self: flex-end;
        width: 75%;
        display: flex;
        justify-content: flex-end;
        position: fixed;
        /* background: #fff; */
        left: 25%;
        padding: 10px 4%;
        transition: .5s;
        transform: translate(0,0);
        &.hide{
          transform: translate(0,-111%);
        }
        &.show{
          transform: translate(0,0);
        }
        .MuiButton-label {
          font-family: ProximaNova-Sbold;
        }
        
        .back-button {
          border-radius: 20px;
          box-shadow: $box-shadow;
          padding: 5px 20px;
          background-color: #fff;
          font-weight: bold;
        }
      }

      .image-container {
        padding: 10px 0;
        align-self: center;
        margin-top: 55px;
        .details-image {
          width: 75%;
          display: block;
          margin: 0 auto 30px;
          border-radius: 10px;
          box-shadow: $box-shadow;
        }

        .details-image-small {
          display: inline-block;
          margin: 0 auto 15px;
          border-radius: 5px;
          box-shadow: $box-shadow;
          float: right;
          vertical-align: top;
        }
      }

      .subItem-container {
        padding: 1rem 0 0;
        align-self: flex-start;
        width: 100%;
      }
      .in-line-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42vh; /* Optional: Set container height */
        .details-image-in-line {
          transform: scale(0.3);
          //display: block;
          border-radius: 10px;
          box-shadow: $box-shadow;
        }
      }
      .in-line-image-container2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42vh; /* Optional: Set container height */
        .details-image-in-line {
          transform: scale(0.2);
          //display: block;
          border-radius: 10px;
          box-shadow: $box-shadow;
        }
      }
    }
  }

  @include mobile {
    .service-details-container {
      .details-content {
        padding: 0 20px 60px 20px;
        // overflow-y: auto;

        .image-container {
          .details-image {
            width: 90%;
          }
        }

        .subItem-container .des-title {
          font-size: 2.5em;
        }

        .back-button-container {
          width: 100%;
          left: 0;
          .back-button{
            width: 80px;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }

}
@import '../../../styles/const.scss';
@import '../../../styles/mixins.scss';
.am {
  
  .gradient-svg {
    position: fixed;
    margin-top: -1000px;
    margin-left: -1000px;
  }

  .main-container {
    height: 100%;
    
    .desktop-view {
      height: 100%;
      .am-modal-page{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 5;
        left: 25%;
        width: 75%;
        background-color: #fff;
        z-index: 5;
        overflow: auto;
        height: calc(100 * var(--vh));
        // margin-left: $menu-width;
        .content{
          margin-left:0 ;
        }
      }
      >.content {
        overflow-y: hidden;
      }
      .content {
        height: 100%;
        position: relative;
        margin-left: $menu-width;
      }
    }
  
    .mobile-view {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      height: 100%;
      >.content {
        overflow-y: hidden;
      }
      .am-modal-page{
        position: fixed;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 5;
        left: 0%;
        width: 100%;
        background-color: #fff;
        z-index: 5;
        overflow: auto;
        height: calc(100 * var(--vh) - 80px);
        // margin-left: $menu-width;
        .content{
          padding: 0;
          margin: 0;
        }
      }
      .content {
        height: 100%;
        margin-top: 8px;
        padding-top: 6px;
      }
      @include mobile {
        >.content{
          padding-top: 0;
          margin-top: 80px;
          position: relative;
        }
      }
    }
  
  }
  
}